import { Component, Vue } from "vue-property-decorator";
import { VuejsDatatableFactory } from "vuejs-datatable";
import { GET_PROFILE_AGENT, GET_LOGIN_HISTORY_AGENT, UPDATE_CALLBACK, UPDATE_WHITELIST, DELETE_WHITELIST } from "../../../api/account"
import Model from "@/models/Model";
import moment from 'moment';
import VueClipboards from 'vue-clipboards';

Vue.use(VuejsDatatableFactory);
Vue.use(VueClipboards);

@Component({
    filters: {
        currency(val: any) {
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        dateTime(val: any) {
            return val ? moment(val).local().format('DD-MM-YYYY HH:mm:ss') : ''
        }
    }
})
default class Profile extends Vue {

    private displayShow = true
    private dataLoading = false;
    private role = ""
    private information = {
        name: "",
        phone: "",
        type: "",
        currency: "THB",
        certificateKey: "",
        creditLimit: 0,
        typeAgent : "",
        typeAgentAPI : "",
        urlCallback : "",
        ipWhitelist : "",
        limit : 0,
        ourPT: "0",
        email: ""
    }
    private dataModalDelete = {
        list: "",
        key: ""
    }

    private limitList = [
        {name: 'Limit A', min: 40, max: 200 , value: 1, check : true},
        {name: 'Limit B', min: 200, max: 1000 , value: 2, check : false},
        {name: 'Limit C', min: 2000, max: 10000, value: 3, check : false},
        {name: 'Limit D', min: 10000, max: 50000 , value: 4, check : false},
        {name: 'Limit E', min: 50000, max: 100000 , value: 5, check : false}
    ]
    private listInput: any = []
    private dataLoginHistory: any = []
    private dataWhiteList: any = []
    private cookieData: any = Vue.$cookies.get("login")
    private model: Model;
    private mainUser = true
    private subAccountMenus = {
        profile: false
    }
    constructor() {
        super();
        this.model = new Model();
    }

    async mounted() {
        if (Object.prototype.hasOwnProperty.call(this.cookieData, 'mainUsername')) {
            this.mainUser = false
            this.$store.state.profile.subAccountMenus.filter(val => {
                if (val.name == 'MENU_ACCOUNT') {
                    if (val.permission == 'readonly') {
                        this.subAccountMenus.profile = false
                    } else if (val.permission == 'editable') {
                        this.subAccountMenus.profile = true
                    }
                }
            });
        } else {
            this.mainUser = true
        }
        
        const loader = this.$loading.show({
            color: '#CFA137',
            loader: 'spinner',
            width: 145,
            height: 235,
            backgroundColor: '#000000',
            opacity: 0.5,
        });
        this.addRouteName()
        await this.getProfileData()
        await this.getLoginHistory()
        loader.hide()
    }

    private addRouteName() {
        this.model.Name = this.$route.name;
        this.$store
            .dispatch("addRouteName", this.model)
    }

    public changeInputValue(key: string, value: string) {
        this.displayShow = false
        this.listInput[key] = value
        this.displayShow = true
    }

    private async getLoginHistory() {
        this.dataLoading = true
        this.dataLoginHistory = await GET_LOGIN_HISTORY_AGENT(this.cookieData.username);
        this.dataLoading = false
    }

    public showModalDelete(list, key) {
        this.dataModalDelete.list = list
        this.dataModalDelete.key = key
    }

    public async deleteWhiteList(key) {
        let data = {}
        data = {
            username: this.cookieData.realUsername,
            index: key
        }
        const resultDeleteWhiteList = await DELETE_WHITELIST(data)
        if (resultDeleteWhiteList.success) {
            const textAlert = (this.$store.state.locale == 'th-TH') ? 'ลบ Whitelist สำเร็จ' : 'Whitelist deleted successfully.'
            this.$swal({
                text: textAlert,
                icon: "success",
                timer: 2000,
                showConfirmButton: false,
                timerProgressBar: true,
                onClose: () => {
                    window.location.replace('/profile');
                }
            });
        }
    }
    
    private async getProfileData() {
        const rawProfile = await GET_PROFILE_AGENT(this.cookieData.realUsername)
        const data = rawProfile[0]
        console.log(data)
        this.role = data.roles[0]
        this.information.certificateKey = data.certificateKey
        this.information.name = data.name
        this.information.creditLimit = data.creditLimit
        this.information.currency = 'THB'
        this.information.urlCallback = data.urlCallback
        this.dataWhiteList = data.whiteListApi
        if (Object.prototype.hasOwnProperty.call(data, 'information')) {
            this.information.phone = data.information.contact.phone
            this.information.email = data.information.contact.email
        }
        for(const value of data.royaltySetting.products)
        {
            if(value.productId == 'POKER')
            {
                this.information.ourPT = value.rate.THB + "%"
                this.information.limit = value.limit
                this.information.typeAgent = value.typeAgent
                this.information.typeAgentAPI = value.typeAgentAPI
                this.limitList[value.limit - 1].check = true
            }
        }
    }

    public async sendWhiteListIP(event: any) {
        event.preventDefault();
        if(this.subAccountMenus.profile || this.mainUser)
        {
        let data = {}
        data = {
            username: this.cookieData.realUsername,
            whiteListApi: this.listInput.ipWhitelist
        }
        const resultUpdateWhiteList = await UPDATE_WHITELIST(data)
        if (resultUpdateWhiteList.success) {
            const textAlert = (this.$store.state.locale == 'th-TH') ? 'เพิ่ม Whitelist สำเร็จ' : 'Whitelist was added successfully.'
            this.$swal({
                text: textAlert,
                icon: "success",
                timer: 2000,
                showConfirmButton: false,
                timerProgressBar: true,
                onClose: () => {
                    window.location.replace('/profile');
                }
            });
        }
        }
        else
        {
            return
        }
    }

    public async sendCallbackUrl(event: any) {
        event.preventDefault();
        if(this.subAccountMenus.profile || this.mainUser)
        {
        let data = {}
        const urlRegex = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
        const urlCheck = this.listInput.callbackUrl.match(urlRegex);
        if (!urlCheck) {
            this.$swal({
                text: `${this.$t('PROFILE.CHECK_CALLBACK').toString()}`,
                icon: "error",
                confirmButtonColor: '#CFA137',
                confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
            });
            return;
        } else {
            data = {
                username: this.cookieData.realUsername,
                callbackUrl: this.listInput.callbackUrl
            }
            const resultUpdateCallback = await UPDATE_CALLBACK(data)
            if (resultUpdateCallback.success) {
                this.$swal({
                    text: `${this.$t('PROFILE.CALLBACK_SUCCESS').toString()}`,
                    icon: "success",
                    timer: 2000,
                    showConfirmButton: false,
                    timerProgressBar: true,
                    onClose: () => {
                        window.location.replace('/profile');
                    }
                });
            }
        }
        } else
        {
            return
        }

    }

}
export default Profile